import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './Footer.css'

const Footer = () => {
  return (
    <Box className='footer'>
      <Container className='footer-text'>
        <Typography variant="h5" gutterBottom>
          CONTACTS:
        </Typography>
        <Typography variant="body1">
          #123, 70Th Cross 2Nd Floor, SVA Arcade 5Th Block, Rajajinagara Bengaluru - 560010
        </Typography>
        <Typography variant="body1">
          +91 8431 6630 25 / +91 6363 5078 58
        </Typography>
        <Typography variant="body1">
          <a href='mailto:@hr@uplsnipe.com'>hr@uplsnipe.com</a>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

import React from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  contactNumber: Yup.string().matches(
    /^[0-9]{10}$/,
    'Invalid contact number'
  ),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
});

const initialValues = {
  name: '',
  email: '',
  contactNumber: '',
  city: '',
  country: '',
};

const onSubmit = async (values, { resetForm }) => {
  console.log('Form data:', values);
  const headers = {
    'Content-Type': 'application/json',
    };
  try {
    await axios.post('https://backend.uplsnipe.com/api/franchiseeDetail', values, {headers});
    alert('Data submitted successfully');
    resetForm();
  } catch (error) {
    console.error('Error submitting data:', error);
    alert('Failed to submit data');
  }
};

const FormComponent = () => {
  return (
    <Box sx={{ bgcolor: '#f9f9f9', py: 5 }}>
      <Container
        sx={{
          width: '100%',
          '@media (min-width:600px)': { width: '50%' },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Submit Form
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name && errors.name}
                />
              </Box>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
              </Box>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="contactNumber"
                  label="Contact Number"
                  fullWidth
                  error={errors.contactNumber && touched.contactNumber}
                  helperText={
                    errors.contactNumber &&
                    touched.contactNumber &&
                    errors.contactNumber
                  }
                />
              </Box>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="city"
                  label="City"
                  fullWidth
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city && errors.city}
                />
              </Box>
              <Box mb={2}>
                <Field
                  as={TextField}
                  name="country"
                  label="Country"
                  fullWidth
                  error={errors.country && touched.country}
                  helperText={errors.country && touched.country && errors.country}
                />
              </Box>
              <Box mb={2} display="flex" alignItems="center" justifyContent="center">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default FormComponent;

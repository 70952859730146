import { configureStore } from '@reduxjs/toolkit';
import benefitsReducer from './benefitsSlice';
import termsNconditionSlice from './termsSlice';

const store = configureStore({
  reducer: {
    benefits: benefitsReducer,
    termsNcondition: termsNconditionSlice,
  },
});

export default store;

import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import './CourseFee.css';

const courseFeeData = [
  { category: 'Basic Courses', courses: [
    { name: 'Basic', fee: 'Rs. 499/-', mode: 'Off Line' },
    { name: 'Fresher', fee: 'Rs. 1,500/-', mode: 'Off Line' }
  ]},
  { category: 'Advance Courses', courses: [
    { name: 'Certificate Course(PG Diploma)', fee: 'Rs. 35,000/-', mode: 'On Line' },
    { name: 'Industry Readiness Program', fee: 'Rs. 20,000/-', mode: 'On Line' },
    { name: 'Career Bridge', fee: 'Rs. 30,000/-', mode: 'On Line' },
    { name: 'Coding Bootcamp Developers', fee: 'Rs. 5,000+1 Month CTC', mode: 'On Line' },
    { name: 'Coding Bootcamp Trainers', fee: 'Rs. 30,000/-', mode: 'On Line' },
    { name: 'Premium Upskill Program', fee: 'Rs. 50,000/-', mode: 'On Line' }
  ]}
];

const CourseFee = () => {
  return (
    <Box className="course-fee-how">
      <Container>
        <Typography variant="h4" gutterBottom>
          COURSE FEE
        </Typography>
        {courseFeeData.map((categoryData, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom className="category-title-how">
              {categoryData.category}
            </Typography>
            <Grid container spacing={4}>
              {categoryData.courses.map((course, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <Card className="course-card-how">
                    <CardContent>
                      <Typography variant="h6" className="course-name-how">
                        {course.name}
                      </Typography>
                      <Typography variant="body1" className="course-fee-how">
                        {course.fee}
                      </Typography>
                      <Typography variant="body2" className="course-mode-how">
                        {course.mode}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default CourseFee;

const benefits = [
    { id: 1, title: 'Quality Training', description: 'Provides high-quality IT education', icon: 'Money'},
    { id: 2, title: 'Established Brand', description: 'Leverages the reputation of an established institute', icon: 'BusinessCenter' },
    { id: 3, title: 'Support System', description: 'Offers comprehensive support and guidance', icon: 'Campaign' },
    { id: 4, title: 'Accessible Resources', description: 'Provides access to proven training materials and resources', icon: 'BusinessCenter' },
    { id: 5, title: 'Marketing Assistance', description: 'Offers guidance with marketing and advertising expert team', icon: 'Campaign' },
    { id: 6, title: 'Continuous Improvement', description: 'Supports ongoing training and updates', icon: 'Campaign' },
    { id: 7, title: 'Business Guidance', description: 'Provides guidance on business operations and management', icon: 'Campaign' },
    { id: 8, title: 'Network Expansion', description: 'Facilitates expansion into new markets', icon: 'Campaign' },
    { id: 9, title: 'Profit Potential', description: 'Presents a lucrative business opportunity in the growing IT sector', icon: 'Campaign' },
    { id: 10, title: 'Expert Guidance', description: 'Access to seasoned trainers with industry experience', icon: 'Campaign' },

]
export default benefits
export const industryRead_sem1 = [
    {
      id: 1,
      title: 'Quantitative Aptitude',
      description: 'Algebra, Area, Averages, Mean, Median and Mode, LCM and HCF, Logarithms, Number Systems, Percentage, Permutation and Combinations, Pipes and Cisterns, Probability, Profit and Loss, Races, Ratio, Proportions and Partnerships, Sets, Simple and Compound Interest, Simplification and Approximation, Speed, Distance and Time, Time and Work, Trains, boats and streams, Trigonometry, Heights, and Distances'
    },
    {
      id: 2,
      title: 'Data Interpretation and Logical Aptitude',
      description: 'Coding, Decoding, Logical Reasoning, Blood Relationship, Coding Relationship, Data Interpretation, Number Series, Puzzles'
    },
    {
      id: 3,
      title: 'Soft Skills',
      description: 'Positive Thinking, overcoming shy barriers, time management, leadership qualities, SWOT Analysis, Goal setting, Self-Motivation, Resume Building, Interview Technique, Email Etiquette, Decision-making and work ethics'
    },
    {
      id: 4,
      title: 'Interview Process',
      description: 'The pre-requisite of the Interview Process is, Right experiences, Projects, Programming and Language, Challenges, Mistakes, leadership, conflicts, and what you did differently'
    },
    {
      id: 5,
      title: 'Algorithms',
      description: 'Algorithms, Time complexity, Recursion, Divide and Conquer, Greedy, BFS, DFS, Binary Search, Dynamic programming, Graphs'
    },
    {
      id: 6,
      title: 'Data Structure',
      description: 'Array, Stack, Queue, Linked List, sorting technique, Tree, Memory (stack v/s Heap)'
    },
    {
      id: 7,
      title: 'Programming',
      description: 'Bit Manipulation, OOPS Concepts, class and objects, constructor, Threads, C, C++, java and Python fundamentals'
    },
    {
      id: 8,
      title: 'Written and Interview Sample Papers',
      description: 'Sample papers with solution (10 papers)'
    }
  ];
export const industryRead_sem2= [
    {
      id: 1,
      title: 'Java Full Stack (I)',
      description: 'Core Java, JDBC Programming, Servlet, Spring, Spring Data JPA, Spring boot, SOA, Microservice architecture Design pattern, HTML, CSS, BOOTSTRAP, REACTJS, Typescript, and Angular'
    },
    {
      id: 2,
      title: 'Python and Data Science (II)',
      description: 'Core python, Advanced Python, statistics, Machine learning and Deep learning'
    },
    {
      id: 3,
      title: 'Automation Testing (III)',
      description: 'Manual Testing, Core Java, Selenium, API Testing'
    },
    {
      id: 4,
      title: 'Front End Technologies (IV)',
      description: 'HTML5, CSS3, JavaScript, Typescript, angular, ReactJs, NodeJS, MangoDB, Vue.js, MEANSTACK'
    },
    {
      id: 5,
      title: 'Capstone Project',
      description: 'Demonstration of sample project and assignment'
    },
    {
      id: 6,
      title: 'Live Project',
      description: 'Implement one module'
    }
  ];
//   export  default {industryRead_sem1, industryRead_sem2};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/footer.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    /* color: white; */
    padding: 32px;
    opacity: 0.8;
    /* margin-top: 32px; */
  }
  .footer-text{
    color: white;
    
  }
  .footer-text p{
    color: white;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yDAAuD;IACvD,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,YAAY;;EAEd;EACA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".footer {\n    background-image: url('../../assets/images/footer.jpg');\n    background-size: cover;\n    background-position: center;\n    /* color: white; */\n    padding: 32px;\n    opacity: 0.8;\n    /* margin-top: 32px; */\n  }\n  .footer-text{\n    color: white;\n    \n  }\n  .footer-text p{\n    color: white;\n  }\n  \n  .footer a {\n    color: white;\n    text-decoration: none;\n    cursor: pointer;\n  }\n  \n  .footer a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Box, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import './InvestmentCosts.css';
import { investmentData } from '../../../data/investmentData';

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Style the Card with zoom-in animation
const AnimatedPaper = styled(Paper)`
  animation: ${zoomIn} 1.6s ease-out forwards;
`;

const InvestmentCosts = () => {
  return (
    <Box className="investment-costs">
      <Container>
        <Typography variant="h4" gutterBottom>
          FRANCHISE INVESTMENT COSTS
        </Typography>
        <AnimatedPaper>
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="investment costs table">
            <TableHead>
              <TableRow>
                <TableCell>Level</TableCell>
                <TableCell align="right">Deposit</TableCell>
                <TableCell align="right">Minimum Number of Students</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investmentData.map((row) => (
                <TableRow key={row.level}>
                  <TableCell component="th" scope="row">{row.level}</TableCell>
                  <TableCell align="right">{row.deposit}</TableCell>
                  <TableCell align="right">{row.students}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </AnimatedPaper>
      </Container>
    </Box>
  );
};

export default InvestmentCosts;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-container {
    margin-top: 40px;
  }
  
  .course-container img {
    width: 100%;
  }
  


`, "",{"version":3,"sources":["webpack://./src/components/Courses/CourseList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".course-container {\n    margin-top: 40px;\n  }\n  \n  .course-container img {\n    width: 100%;\n  }\n  \n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

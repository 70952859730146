import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography, Button, Grid, Paper } from '@mui/material';
import './CourseList.css'
import processS from '../../assets/images/howitworks/processS.jpg'; 

const CourseList = () => {
  return (
    <Box sx={{ bgcolor: '#e8f5e9', py: 5 }}>
      <Container>
        <Typography variant="h4" gutterBottom>Our Courses</Typography>
        <Grid container spacing={4} >
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/offline-courses">
              <Button variant="contained" color="primary" fullWidth>
                Offline Courses
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/it-training-courses">
              <Button variant="contained" color="primary" fullWidth>
                IT Training Courses
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/advanced-courses">
              <Button variant="contained" color="primary" fullWidth>
                Advanced Courses
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/CertificateCourses">
              <Button variant="contained" color="primary" fullWidth>
              Certificate Courses
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/IndustryReadiness">
              <Button variant="contained" color="primary" fullWidth>
                Industry Readiness
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Box>
        <Paper className="course-container ">
            <img src={processS} alt="About UPL Process" className="process-image" />
          </Paper>
          </Box>
      </Container>
    </Box>
  );
};

export default CourseList;

import React from 'react';
import { Box, Container, Typography, Card, CardContent, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './CoursePage.css';

const CoursePage = ({ title, courses }) => {
    const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: '#f9f9f9', py: 5 }}>
      <Container>
        <Typography variant="h4" gutterBottom>{title}</Typography>
        <IconButton onClick={()=>{navigate(-1)}} sx={{ mt: 1 }}>
            <ArrowBackIcon />
      </IconButton>
        <Grid container spacing={4}>
          {courses.map(course => (
            <Grid item xs={12} sm={6} md={4} key={course.id}>
              <Card className='course-card'>
                <CardContent>
                  <Typography variant="h6">{course.name}</Typography>
                  <Typography variant="body2">{course.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default CoursePage;

import { createSlice } from '@reduxjs/toolkit';
import benefits from '../data/benefit.js';

const initialState = {
  items : benefits,
  // items: [
  //   { id: 1, title: 'Benefit 1', description: 'Description for benefit 1', icon: 'Money'},
  //   { id: 2, title: 'Benefit 2', description: 'Description for benefit 2', icon: 'BusinessCenter' },
  //   { id: 3, title: 'Benefit 3', description: 'Description for benefit 3', icon: 'Campaign' },
  //   { id: 4, title: 'Benefit 4', description: 'Description for benefit 4', icon: 'BusinessCenter' },
  //   { id: 5, title: 'Benefit 5', description: 'Description for benefit 5', icon: 'Campaign' },
  //   { id: 6, title: 'Benefit 6', description: 'Description for benefit 6', icon: 'Campaign' },
  // ],
};

const benefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {},
});

export const selectBenefits = (state) => state.benefits.items;

export default benefitsSlice.reducer;

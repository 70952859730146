import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/upllogo.png';
import menuItems from '../../data/menuItems';
import './Navbar.css';
// const menuItems = [
//   { label: 'Home', to: '/' },
//   { label: 'About Us', to: '/aboutUs' },
//   { label: 'Benefits', to: '/benefits' },
//   { label: 'Course List', to: '/courselist' },
//   { label: 'How it works', to: '/how-it-works' },
//   { label: 'FAQs', to: '/faqs' },
//   { label: 'Connect', to: '/connect' }
// ];
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box display="flex" alignItems="center">
          <img src={logo} alt="UPL Logo" style={{ width: '40px', marginRight: '10px' }} />
          <Typography variant="h6" component="div">
            UPL Franchisee
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box display={{ xs: 'none', md: 'flex' }}>
          {menuItems.map((menu, id)=>(
            <Button key={id} color="inherit" component={Link} to={menu.to}>
            {menu.label}
          </Button>
          ))}
          {/* <Button color="inherit" component={Link} to="/">
            Home
          </Button>
          <Button color="inherit" component={Link} to="/aboutUs">
            About Us
          </Button>
          <Button color="inherit" component={Link} to="/benefits">
            Benefits
          </Button>
          <Button color="inherit" component={Link} to="/courselist">
            Course List
          </Button>
          <Button color="inherit" component={Link} to="/how-it-works">
            How it works
          </Button>
          <Button color="inherit" component={Link} to="/faqs">
            FAQs
          </Button>
          <Button color="inherit" component={Link} to="/connect">
            Connect
          </Button> */}
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={menuOpen} onClose={toggleMenu}>
            <List>
              {menuItems.map(
                (text, index) => (
                  <ListItem button key={index} component={Link} to={text.to}>
                    <ListItemText primary={text.label} />
                  </ListItem>
                )
              )}
              <Divider />
              <ListItem button component={Link} to="/connect">
                <ListItemText primary="Connect" />
              </ListItem>
            </List>
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

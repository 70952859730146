import {useState} from "react";
import { Box, Container, Typography, Grid, Avatar, Card, CardContent, CardActions, Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import styledE from '@emotion/styled';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './TermsConditions.css';
import { selecttermsNcondition } from "../../../redux/termsSlice";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Style the Card with zoom-in animation
const AnimatedCard = styledE(Card)`
  animation: ${zoomIn} 1.6s ease-out forwards;
`;

const TermsConditions = () =>{
  const termsNcondition = useSelector(selecttermsNcondition);
  const [expandedId, setExpandedId] = useState(-1);
  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  
    return(
        <Box className='termsNcond'>
            <Container sx={{ marginTop: 4 }}>
            <Typography variant="h4" gutterBottom>
                Terms and Conditions
            </Typography>
            <Grid container spacing={4}>
              {termsNcondition.map((term, i) => (
                <Grid item xs={12} sm={6} md={4} key={term.id}>
                  <AnimatedCard>
                  <Card sx={{ textAlign: 'center', padding: 2 }}>
                    <Avatar sx={{ margin: '0 auto', marginBottom: 1, bgcolor: '#3442D9' }} variant="rounded">
                        {term.icon}
                    </Avatar>
                    <CardContent>
                      <Typography variant="body1">{term.title}</Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <ExpandMore
                        onClick={()=>handleExpandClick(i)}
                        aria-expanded={expandedId === i}
                        aria-label="show more">
                        <ExpandMoreIcon />
                      </ExpandMore>
                  </CardActions>
                  <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography paragraph>{term.description}</Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                  </AnimatedCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
    )
}
export default TermsConditions;
import { createSlice } from '@reduxjs/toolkit';
import terms from '../data/termsNconditions';
const initialState = {
  items:terms,
};

const termsNconditionSlice = createSlice({
  name: 'termsNcondition',
  initialState,
  reducers: {},
});

export const selecttermsNcondition = (state) => state.termsNcondition.items;

export default termsNconditionSlice.reducer;

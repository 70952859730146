import React from 'react';
import CoursePage from './CoursePage';
import offlineCourses from '../../data/offlineCourses';

const OfflineCourses = () => {
  return( 
  <CoursePage title="Offline Courses" courses={offlineCourses} />
//    <CoursePage title="Advanced Courses" courses={advancedCourses} />;
//    <CoursePage title="IT Training Courses" courses={itTrainingCourses} />;
)
};

export default OfflineCourses;

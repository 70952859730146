const terms = [
    { id: 1, title: 'Revenue Sharing', description: `If franchisor conduct classes through online, franchisor (UPL) share is 60% and franchisee share is 40% \n
    If franchisee conduct classes through offline, franchisee share is 80% and franchisor (UPL) share is 20%`, icon: '1'},
    { id: 2, title: 'Student Payment Procedure', description: `Student fees should be directly paid to the UPL main office through franchise.
    UPL official account which will be communicated to franchise rather main office 
    No cash accepted from franchise. 
    Once paid transaction id should be kept as a record in franchise for the reference
    `, icon: '2' },
    { id: 3, title: 'Course Starting Month', description: '(Jan, March, May, July, September, November) Duration 2 Months', icon: '3' },
    { id: 4, title: 'Training and Support', description: `The franchisor may be required to provide initial training & ongoing support to the franchisee, 
    including assistance with marketing, operations, management & Faculty Training`, icon: '4' },
    { id: 5, title: 'Intellectual Property', description: `The franchise agreement will specify the franchisee's right to use the 
    franchisor's trademarks, copyrights, and other intellectual property`, icon: '5' },
    { id: 6, title: 'Term and Termination', description: `The franchise agreement will specify the length of the franchise term and 
    the conditions under which either party can terminate the agreement.
     This may include provisions related to breach of contract, non-payment of fees, or other reasons`, icon: '6' },
    { id: 7, title: 'Advertising & Marketing', description: 'Use all design of advertising & marketing shared by UPL franchise as no rights to create their own design for promotion activities', icon: '7' },
    { id: 8, title: 'Dispute Resolution', description: `The franchise agreement may specify the process for resolving disputes 
    between the franchisor and franchise including any arbitration or mediation procedures`, icon: '8' },
    { id: 9, title: 'Performance Requirements', description: `The franchise agreement may specify certain performance requirements that the franchisee must meet, 
    such as Admission targets, Student satisfaction, Feedback ratings, or other metrics`, icon: '9' },
    { id: 10, title: 'Renewal Fees', description: `Franchisee need to Pay 10,000 per year.
     The Renewal Fees is non-refundable. Renewal fees increases 10% on every year`, icon: '10' },

]
export default terms;
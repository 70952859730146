const offlineCourses = [
  { id: 1, name: 'CorelDraw', fees: 'Rs. 499/-' },
  { id: 2, name: 'DTP (Desktop Publishing)', fees: 'Rs. 499/-' },
  { id: 3, name: 'Graphic Design', fees: 'Rs. 499/-' },
  { id: 4, name: 'MSCIT (Microsoft Certified Information Technology)', fees: 'Rs. 499/-' },
  { id: 5, name: 'Adobe Photoshop', fees: 'Rs. 499/-', description: 'This is a raster graphics editor widely used for photo editing and digital art.' },
  { id: 6, name: 'Adobe Illustrator', fees: 'Rs. 499/-', description: 'It is a vector graphics editor used to create logos, icons, drawings, typography, and illustrations.' },
  { id: 7, name: 'Adobe InDesign', fees: 'Rs. 499/-', description: 'This is a desktop publishing software application used to create posters, flyers, brochures, magazines, newspapers, presentations, books, and ebooks.' },
  { id: 8, name: 'Microsoft Office Suite', fees: 'Rs. 499/-', description: 'Includes Word, Excel, PowerPoint, and Outlook. These are essential software for word processing, spreadsheet management, presentation creation, and email communication.' },
  { id: 9, name: 'AutoCAD', fees: 'Rs. 499/-', description: 'It is a computer-aided design (CAD) software used for 2D and 3D drafting and designing.' },
  { id: 10, name: 'HTML & CSS', fees: 'Rs. 499/-', description: 'Learning the basics of HTML (Hypertext Markup Language) and CSS (Cascading Style Sheets) can be beneficial for web development.' },
  { id: 11, name: 'Digital Marketing', fees: 'Rs. 499/-', description: 'This course covers various aspects of online marketing, including social media marketing, search engine optimization (SEO), content marketing, email marketing, and more.' },
  { id: 12, name: 'Programming Basics', fees: 'Rs. 499/-', description: 'Introduction to programming languages like Python, JavaScript, or Java can provide a foundational understanding of coding principles.' },
  { id: 13, name: 'Basic Accounting', fees: 'Rs. 499/-', description: 'Courses on basic accounting principles can be helpful for individuals interested in finance or business administration.' },
  { id: 14, name: 'English Language Skills', fees: 'Rs. 499/-', description: 'Courses focusing on grammar, vocabulary, writing, and speaking can improve communication skills, which are essential in many fields.' }
];

export default offlineCourses;

export  const investmentData = [
    { level: 'Metropolitan Franchisee', deposit: 'Rs. 1,00,000/-', students: '500 Per Year' },
    { level: 'District Franchisee', deposit: 'Rs. 75,000/-', students: '350 Per Year' },
    { level: 'Taluk Level', deposit: 'Rs. 50,000/-', students: '200 Per Year' },
    { level: 'Hobli Level', deposit: 'Rs. 25,000/-', students: '100 Per Year' },
  ];
 export const investmentDataWritten = [
    { area: 'Metropolitan', students: 500, revenue: 'Rs. 50 Lakhs', share: 'Rs. 20 Lakhs', expenses: 'Rs. 3.5 Lakhs', profit: 'Rs. 16.5 Lakhs' },
    { area: 'District', students: 350, revenue: 'Rs. 35 Lakhs', share: 'Rs. 14 Lakhs', expenses: 'Rs. 3.0 Lakhs', profit: 'Rs. 11 Lakhs' },
    { area: 'Taluk', students: 200, revenue: 'Rs. 20 Lakhs', share: 'Rs. 08 Lakhs', expenses: 'Rs. 2.5 Lakhs', profit: 'Rs. 5.5 Lakhs' },
    { area: 'Hobli', students: 100, revenue: 'Rs. 10 Lakhs', share: 'Rs. 04 Lakhs', expenses: 'Rs. 2.0 Lakhs', profit: 'Rs. 02 Lakhs' },
  ];
import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {industryRead_sem1} from '../../data/industryReadiness';
import {industryRead_sem2} from '../../data/industryReadiness';

const IndustryReadiness = () => {
    const navigate = useNavigate();
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Industry Readiness Program
      </Typography>
      <IconButton onClick={()=>{navigate(-1)}} sx={{ mt: 1 }}>
        <ArrowBackIcon />
      </IconButton>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Semester I: Aptitude
            </Typography>
            <List>
              {industryRead_sem1.map((unit) => (
                <ListItem key={unit.id}>
                  <ListItemText primary={`UNIT ${unit.id}: ${unit.title}`} secondary={unit.description} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mb:2 }}>
            <Typography variant="h5" gutterBottom>
              Semester II: Core Technologies
            </Typography>
            <List>
              {industryRead_sem2.map((unit) => (
                  <ListItem key={unit.id}>
                    <ListItemText primary={`UNIT ${unit.id}: ${unit.title}`} secondary={unit.description} />
                  </ListItem>
                ))}
              {/* <ListItem>
                <ListItemText primary="UNIT 001: Java Full Stack (I)" secondary="Core Java, JDBC Programming, Servlet, Spring, Spring Data JPA, Spring boot, SOA, Microservice architecture Design pattern, HTML, CSS, BOOTSTRAP, REACTJS, Typescript, and Angular" />
              </ListItem>
              <ListItem>
                <ListItemText primary="UNIT 002: Python and Data Science (II)" secondary="Core python, Advanced Python, statistics, Machine learning and Deep learning" />
              </ListItem>
              <ListItem>
                <ListItemText primary="UNIT 003: Automation Testing (III)" secondary="Manual Testing, Core Java, Selenium, API Testing" />
              </ListItem>
              <ListItem>
                <ListItemText primary="UNIT 004: Front End Technologies (IV)" secondary="HTML5, CSS3, JavaScript, Typescript, angular, ReactJs, NodeJS, MangoDB, Vue.js, MEANSTACK" />
              </ListItem>
              <ListItem>
                <ListItemText primary="UNIT 005: Capstone Project" secondary="Demonstration of sample project and assignment" />
              </ListItem>
              <ListItem>
                <ListItemText primary="UNIT 006: Live Project" secondary="Implement one module" />
              </ListItem> */}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IndustryReadiness;

import { Box } from "@mui/material";
import Carousel from "../Carousel";
import Benefits from "../Benefits/Benefits"
import FAQ from "../faq/FAQ"

const Home = () => {
    return (
        <>
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Carousel />
            </Box>
            <Box sx={{ width: '100%', padding: 0, margin: 0 }}>
                <Benefits />
                <FAQ />
            </Box>
        </>
    )
}
export default Home;
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Eligibility from './Eligibility';
import TermsConditions from './TermsNcondition/TermsConditions';
import Documents from './Documents';
import RegisterProcess from './RegistrationProcess';
import InvestmentCosts from './InvestmentCosts/InvestmentCosts';
import InvestmentWritten from './InvestmentCosts/InvestmentWritten';
import CourseFee from './CourseFee/CourseFee';
import ProcessFlow from './ProcessFlow/processFlow';

const HowItWorks = () => {
  return (
        <Box>
               <Eligibility />
               <InvestmentCosts />
               <TermsConditions />
               <InvestmentWritten />
                <Documents />
                <RegisterProcess />
                <CourseFee />
                <ProcessFlow />
                {/* <ol>
                <li>Call us at +91 6363507858</li>
                <li>Submit Documents</li>
                <li>Background verification and eligibility assessment</li>
                <li>Get on-board</li>
                </ol> */}
           
        </Box>
  );
};

export default HowItWorks;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investment-written {
    padding: 40px 0;
    background-color: #f5f5f5;
  }
  
  .info-card {
    background-color: #1976d2;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .table-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  table {
    min-width: 650px;
  }
  
  thead {
    background-color: #1976d2;
  }
  
  thead th {
    color: #fff;
    font-weight: bold;
  }
  
  tbody tr:hover {
    background-color: #e3f2fd;
  }
  
  tbody td {
    padding: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HowItWorks/InvestmentCosts/InvestmentWritten.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf","sourcesContent":[".investment-written {\n    padding: 40px 0;\n    background-color: #f5f5f5;\n  }\n  \n  .info-card {\n    background-color: #1976d2;\n    color: #fff;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    overflow: hidden;\n    margin-bottom: 20px;\n  }\n  \n  .table-container {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    overflow: hidden;\n  }\n  \n  table {\n    min-width: 650px;\n  }\n  \n  thead {\n    background-color: #1976d2;\n  }\n  \n  thead th {\n    color: #fff;\n    font-weight: bold;\n  }\n  \n  tbody tr:hover {\n    background-color: #e3f2fd;\n  }\n  \n  tbody td {\n    padding: 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

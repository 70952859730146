import { Box, Grid, Typography,Container } from '@mui/material';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Check from '@mui/icons-material/Check';
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Style the Card with animation
const AnimatedBox= styled(Box)`
  animation: ${fadeIn} 1.6s ease-out forwards;
`;
const Documents = () =>{
    return(
        <Box sx={{bgcolor: '#e8f5e9', p: '30px'}}>
            <Container sx={{ marginTop: 4 }}>
            <Typography variant="h4" gutterBottom>Documents Required</Typography>
            {/* Add content for Channel Partner Eligibility */}
            <AnimatedBox>
            <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {/* <Box display="flex" alignItems="center" border={1} p={2} borderRadius={4} transition="background-color 0.3s" bgcolor="#f0f0f0" > */}
                        <Box display="flex" alignItems="center" sx={{ p: 2, border: '1px solid grey', borderRadius: 4, bgcolor: '#3442D9', '&:hover': {bgcolor: '#0066CC'} }}>
                            <CheckCircleIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body1">
                        	Certificate of incorporation
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" sx={{ p: 2, border: '1px solid grey', borderRadius: 4, bgcolor: '#3442D9', '&:hover': {bgcolor: '#0066CC'} }}>
                            <AssignmentIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body1">
                        	GST Number
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" sx={{ p: 2, border: '1px solid grey', borderRadius: 4, bgcolor: '#3442D9', '&:hover': {bgcolor: '#0066CC'} }}>
                            <Check sx={{ marginRight: 1 }} />
                        <Typography variant="body1">
                        	Aadhar Card
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" sx={{ p: 2, border: '1px solid grey', borderRadius: 4, bgcolor: '#3442D9', '&:hover': {bgcolor: '#0066CC'} }}>
                            <GroupAddIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body1">
                            Two Passport size photos of Franchise Owners
                        </Typography>
                        </Box>
                    </Grid>
            </Grid>
            </AnimatedBox>
            </Container>
        </Box>
    )
}
export default Documents;
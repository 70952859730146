const advancedCourses = [
  { id: 1, name: 'Java Full Stack with React', fees: 'Rs. 35,000/-', description: 'This involves proficiency in Java programming language for both front-end and back-end development, along with expertise in React.js for building interactive user interfaces. It encompasses skills in HTML, CSS, JavaScript, React Router, Redux, Node.js, Express.js, and database management systems like MySQL or MongoDB.' },
  { id: 2, name: '.NET Full Stack with Angular', fees: 'Rs. 35,000/-', description: 'A .NET Full Stack course typically covers a broad range of topics encompassing both frontend and backend development using Microsoft\'s .NET framework. It consists of Frontend Development (HTML/CSS/JavaScript Fundamentals, Frontend Frameworks, Responsive Design, UI/UX Design Principles) Backend Development (C# Programming Language, ASP.NET Core, Database Integration, Web Development Tools) Additional Topics (Version Control with Git Deployment and DevOps, Security Best Practices, Performance Optimization, Soft Skills).' },
  { id: 3, name: 'MEAN stack', fees: 'Rs. 35,000/-', description: 'The MEAN stack is a popular JavaScript-based full-stack framework for building dynamic web applications. It comprises MongoDB, Express.js, Angular (or AngularJS), and Node.js. MEAN stack course covers MongoDB (Introduction to MongoDB, Advanced MongoDB) Express.js (Introduction to Express.js, Building RESTful APIs with Express.js) Angular (or AngularJS) (Introduction to Angular, Building Single-Page Applications (SPAs) with Angular) Node.js (Introduction to Node.js, Building Backend Services with Node.js. Additional Topics (Version Control with Git, Deployment and DevOps, Security Best Practices, Performance Optimization, Soft Skills).' },
  { id: 4, name: 'MERN Stack', fees: 'Rs. 35,000/-', description: 'The MERN Full Stack Development course covers frontend technologies like HTML, CSS, JavaScript, and React.js for building interactive user interfaces. On the backend, students learn Node.js and Express.js for server-side development, along with MongoDB for database management. Integration of frontend and backend components, deployment to cloud platforms, and testing/debugging techniques are also included, culminating in a real-world project to apply acquired skills.' },
  { id: 5, name: 'Testing/QA Automation', fees: 'Rs. 35,000/-', description: 'This involves proficiency in automated testing frameworks and tools like Selenium, Appium, JUnit, TestNG, and frameworks for behavior-driven development (BDD) like Cucumber. Knowledge of software testing principles, test case design, and test automation best practices is essential.' },
  { id: 6, name: 'Front-End Development', fees: 'Rs. 35,000/-', description: 'Focuses on advanced skills in HTML5, CSS3, JavaScript, and modern front-end frameworks such as React.js, Angular, or Vue.js. It also covers responsive web design, web performance optimization, and front-end build tools like Webpack.' },
  { id: 7, name: 'AI/ML', fees: 'Rs. 35,000/-', description: 'Artificial Intelligence (AI) and Machine Learning (ML) involve building algorithms and models that can learn from data and make predictions or decisions. Proficiency in AI/ML includes understanding algorithms like linear regression, decision trees, neural networks, and tools/libraries like TensorFlow, Keras, scikit-learn, and PyTorch.' },
  { id: 8, name: 'Agile/Scrum', fees: 'Rs. 35,000/-', description: 'Agile and Scrum are methodologies used in software development for iterative and incremental project management. Proficiency in Agile/Scrum involves understanding Agile principles, Scrum framework, roles (e.g., Scrum Master, Product Owner, Development Team), ceremonies (e.g., Sprint Planning, Daily Standup, Sprint Review), and artifacts (e.g., Product Backlog, Sprint Backlog, Burndown Chart).' },
  { id: 9, name: 'Backend Development', fees: 'Rs. 35,000/-', description: 'Advanced courses in backend development cover server-side programming languages such as Node.js, Python, Ruby, or Java. Topics include RESTful API design, database management, server architecture, and security best practices.' },
  { id: 10, name: 'Big Data Technologies', fees: 'Rs. 35,000/-', description: 'Advanced courses in big data technologies cover Hadoop, Spark, Kafka, and other tools used for processing and analyzing large datasets. They also cover data warehousing, ETL processes, and data visualization techniques.' },
  { id: 11, name: 'Artificial Intelligence and Machine Learning', fees: 'Rs. 35,000/-', description: 'Focuses on advanced topics such as deep learning, neural networks, natural language processing, and computer vision. Courses often use frameworks like TensorFlow, Keras, or PyTorch for building and deploying AI models.' },
  { id: 12, name: 'Cybersecurity', fees: 'Rs. 35,000/-', description: 'Advanced cybersecurity courses cover ethical hacking, penetration testing, network security, cryptography, incident response, and security management. They often include hands-on labs and simulations.' }
];

export default advancedCourses;

import React from 'react';
import { useSelector } from 'react-redux';
import { selectBenefits } from '../../redux/benefitsSlice';
import { Box, Container, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BenefitsIcon from './BenefitsIcons';
import './Benefits.css';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Style the Card with animation
const AnimatedCard = styled(Card)`
  animation: ${fadeIn} 1.6s ease-out forwards;
`;
const Benefits = () => {
  const benefits = useSelector(selectBenefits);

  return (
    <Box className='benefits'>
      <Container sx={{ marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Benefits of Franchise
        </Typography>
        <Grid container spacing={2}>
          {benefits.map((benefit) => (
            <Grid item xs={12} sm={6} md={4} key={benefit.id}>
                <AnimatedCard>

              <Card className='benefit-card' >
                <Avatar sx={{ margin: '0 auto', marginBottom: 1 }}>
                    <BenefitsIcon iconName={benefit.icon} />
                </Avatar>
                <CardContent>
                  <Typography variant="h6">{benefit.title}</Typography>
                  <Typography variant="body1">{benefit.description}</Typography>
                </CardContent>
              </Card>
              </AnimatedCard>

            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Benefits;

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Paper } from '@mui/material';
import hero1 from '../assets/images/banner/hero-1.jpg';
import hero2 from '../assets/images/banner/hero-2.jpg';
import hero3 from '../assets/images/banner/hero-3.jpg';


const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Box sx={{ width: '100%', height: '50vh', overflow: 'hidden' }}>
      <Slider {...settings}>
        <Paper sx={{ width: '100%', height: '50vh',  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={hero1} alt="Slide 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Paper>
        <Paper sx={{ width: '100%', height: '50vh',  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={hero2} alt="Slide 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Paper>
        <Paper sx={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={hero3} alt="Slide 3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Paper>
      </Slider>
    </Box>
  );
};

export default Carousel;

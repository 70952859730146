const itTrainingCourses = [
  { id: 1, name: 'Introduction to Computer Science', fees: 'Rs. 1,500/-', description: 'This course provides a broad overview of computer science concepts, including algorithms, data structures, programming languages, and software development methodologies.' },
  { id: 2, name: 'Introduction to Programming', fees: 'Rs. 1,500/-', description: 'Courses like Python for Beginners or JavaScript Fundamentals are excellent starting points for learning programming basics. They cover fundamental concepts such as variables, data types, control structures, and functions.' },
  { id: 3, name: 'Web Development', fees: 'Rs. 1,500/-', description: 'Beginner courses in web development cover HTML, CSS, and JavaScript, the building blocks of web pages. You can also find courses that introduce frameworks like Bootstrap and React, which are widely used in modern web development.' },
  { id: 4, name: 'Introduction to Data Science', fees: 'Rs. 1,500/-', description: 'Data science courses for beginners introduce concepts like data analysis, visualization, and machine learning using tools like Python and libraries such as NumPy, Pandas, and Matplotlib.' },
  { id: 5, name: 'Cybersecurity Fundamentals', fees: 'Rs. 1,500/-', description: 'Beginner courses in cybersecurity cover essential topics such as network security, cryptography, ethical hacking, and security best practices. These courses help you understand the basics of protecting systems and data from cyber threats.' },
  { id: 6, name: 'Networking Basics', fees: 'Rs. 1,500/-', description: 'Courses in networking provide an introduction to concepts like IP addressing, subnetting, network protocols, and network devices. They are essential for understanding how data is transmitted and communicated over networks.' },
  { id: 7, name: 'Introduction to Cloud Computing', fees: 'Rs. 1,500/-', description: 'Cloud computing courses introduce concepts like Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). They cover popular cloud platforms like AWS, Azure, and Google Cloud.' },
  { id: 8, name: 'IT Fundamentals', fees: 'Rs. 1,500/-', description: 'These courses cover foundational IT concepts such as hardware, software, operating systems, and IT infrastructure. They provide a broad understanding of how IT systems work and how they are managed.' },
  { id: 9, name: 'Introduction to Databases', fees: 'Rs. 1,500/-', description: 'Courses in databases introduce relational database concepts, SQL (Structured Query Language), database design, and management systems like MySQL, PostgreSQL, or Microsoft SQL Server.' },
  { id: 10, name: 'Introduction to Linux', fees: 'Rs. 1,500/-', description: 'Beginner courses in Linux cover basic commands, file systems, user management, and shell scripting.' }
];

export default itTrainingCourses;

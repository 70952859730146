// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    height: 40px; /* Adjust the height as needed */
  }
  
  .MuiToolbar-root {
    justify-content: space-between;
    background-color: #3442D9;
  }
  
  .MuiButton-root {
    margin-left: 10px;
  }
  .AppBar{
    background-color: #3442D9;
  }`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,gCAAgC;EAChD;;EAEA;IACE,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,yBAAyB;EAC3B","sourcesContent":[".logo {\n    height: 40px; /* Adjust the height as needed */\n  }\n  \n  .MuiToolbar-root {\n    justify-content: space-between;\n    background-color: #3442D9;\n  }\n  \n  .MuiButton-root {\n    margin-left: 10px;\n  }\n  .AppBar{\n    background-color: #3442D9;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

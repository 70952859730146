import React from 'react';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CampaignIcon from '@mui/icons-material/Campaign';

const iconMap = {
  Money: MoneyIcon,
  BusinessCenter: BusinessCenterIcon,
  Campaign: CampaignIcon,
};

const BenefitsIcon = ({ iconName }) => {
  const IconComponent = iconMap[iconName];
  return IconComponent ? <IconComponent /> : null;
};

export default BenefitsIcon;

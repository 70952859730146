// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefits {
    background-color: #e8f5e9; 
    padding: 32px;
    /* margin-top: 32px; */
  }
  .benefit-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px; /* Set a fixed height for the cards */
    text-align: center;
    padding: 16px;
  }
  
  .benefit-card .MuiCardContent-root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .benefit-card .MuiTypography-h6 {
    margin-bottom: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Benefits/Benefits.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,qCAAqC;IACpD,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".benefits {\n    background-color: #e8f5e9; \n    padding: 32px;\n    /* margin-top: 32px; */\n  }\n  .benefit-card {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 200px; /* Set a fixed height for the cards */\n    text-align: center;\n    padding: 16px;\n  }\n  \n  .benefit-card .MuiCardContent-root {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  \n  .benefit-card .MuiTypography-h6 {\n    margin-bottom: 8px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqs from '../../data/faq';
import './FAQ.css'
const FAQ = () => {
    const [maxHeight, setMaxHeight] = useState(0);
    // const ref = useRef();
    const summaryRefs = useRef([]);
    useEffect(() => {
      const heights = summaryRefs.current.map(ref => ref.scrollHeight);
      setMaxHeight(Math.max(...heights));
    }, []);
    // useEffect(()=>{
    //     if(ref.current){
    //         const maxHeight = Array.from(ref.current.children).reduce((maxHeight, child) => {
    //             return Math.max(maxHeight, child.offsetHeight);
    //           }, 0);
    //           setMaxHeight(maxHeight);
    //     }
    // },[])
  return (
    <Box className="faq">
      <Container >
        <Typography variant="h4" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={1}>
          {faqs.map((faq, index) => (
              <Grid item xs={12} md={6} key={index}>
                  <Accordion sx={{ minHeight: maxHeight }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  ref={el => (summaryRefs.current[index] = el)}
                  sx={{ minHeight: maxHeight ? `${maxHeight}px` : 'auto' }}
                  className="accordion-summary" >
                      <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                      <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                  </Accordion>
              </Grid>
          ))}
        </Grid>
      </Container>
      </Box>
  );
};

export default FAQ;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  @media (max-width: 600px) {
    ul {
      margin-left: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Courses/CertificateCourses.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":["ul {\n    list-style-type: disc;\n    margin-left: 20px;\n  }\n  \n  @media (max-width: 600px) {\n    ul {\n      margin-left: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-fee-how {
    padding: 20px 0;
    background-color: #e8f5e9;
  }
  
  .category-title-how {
    color: #3442D9;
    margin-bottom: 10px;
  }
  
  .course-card-how {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .course-card-how:hover {
    transform: translateY(-5px);
  }
  
  .course-name-how {
    color: #1976d2;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .course-fee-how {
    color: #4caf50;
    font-weight: bold;
    /* margin-bottom: 10px; */
  }
  
  .course-mode-how {
    color: #757575;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HowItWorks/CourseFee/CourseFee.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,wCAAwC;IACxC,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".course-fee-how {\n    padding: 20px 0;\n    background-color: #e8f5e9;\n  }\n  \n  .category-title-how {\n    color: #3442D9;\n    margin-bottom: 10px;\n  }\n  \n  .course-card-how {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    transition: transform 0.3s ease;\n  }\n  \n  .course-card-how:hover {\n    transform: translateY(-5px);\n  }\n  \n  .course-name-how {\n    color: #1976d2;\n    font-weight: bold;\n    margin-bottom: 10px;\n  }\n  \n  .course-fee-how {\n    color: #4caf50;\n    font-weight: bold;\n    /* margin-bottom: 10px; */\n  }\n  \n  .course-mode-how {\n    color: #757575;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-container {
    margin-top: 40px;
  }
  
  .process-container img {
    width: 100%;
    height: 50%;
  }

/* .image-container {
    position: relative;
    padding-bottom: 10%; 
    height: 0;
    overflow: hidden;
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
} */
`, "",{"version":3,"sources":["webpack://./src/components/HowItWorks/ProcessFlow/processFlow.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,WAAW;EACb;;AAEF;;;;;;;;;;;;;GAaG","sourcesContent":[".process-container {\n    margin-top: 40px;\n  }\n  \n  .process-container img {\n    width: 100%;\n    height: 50%;\n  }\n\n/* .image-container {\n    position: relative;\n    padding-bottom: 10%; \n    height: 0;\n    overflow: hidden;\n}\n\n.image-container img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 50%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

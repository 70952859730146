const faqs = [
    {
      question: "What is the initial investment required to open a franchise of UPL institute?",
      answer: "Registration fee, staffing, operating expenses, contingency fund",
    },
    {
      question: "What ongoing fees are associated with the franchise?",
      answer: "Renewal fees",
    },
    {
      question: "What training and support do UPL provide to franchisees?",
      answer: "Material, Training, Upskill & reskill for trainer, Access to recourse, Operations Manuals and Documentation, Quality Assurance and Performance Monitoring, Technology and Software",
    },
    {
      question: "What are the qualifications or criteria for becoming a franchisee?",
      answer: "Required own training centre",
    },
    {
      question: "How long does it typically take to set up and launch a franchise?",
      answer: "15 days",
    },
    {
      question: "What marketing support is available to help attract students?",
      answer: "Guidance for Digital marketing or social media",
    },
    {
      question: "Can I customize the curriculum or teaching methods to suit local needs?",
      answer: "Yes",
    },
    {
      question: "What kind of technology infrastructure and equipment will I need to invest in?",
      answer: "All technology will be provided by UPL",
    },
    {
      question: "What territories or locations are available for franchising?",
      answer: "Metropolitan cities/ District/ Taluka/ Hobli",
    },
    {
      question: "Do you provide assistance with hiring and training staff?",
      answer: "Only in Training staff once in a year and always mentors are available to clarification of any doubts.",
    },
    {
      question: "Can I sell my franchise if I decide to exit the business?",
      answer: "No. UPL only can decide on franchise and proper process to be followed to exit from UPL.",
    },
    {
      question: "How do you ensure quality control across all franchise locations?",
      answer: "Standardized Operating Procedures, Franchisee Training and Support, Quality Assurance Programs, Mystery Shopping and Customer Feedback, Performance Benchmarks and KPIs, Continuous Training and Professional Development, Regular Communication and Feedback Loops",
    },
  ];
  
  export default faqs;
  
import React from 'react';
import { Box, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from '@mui/material';
import './InvestmentWritten.css';
import { investmentDataWritten } from '../../../data/investmentData';


const InvestmentWritten = () => {
  return (
    <Box className="investment-written">
      <Container>
        <Card className="info-card">
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Average Fees per Student: Rs. 10,000/-
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="h4" gutterBottom sx={{ marginTop: 4 }}>
          WRITTEN ON INVESTMENT
        </Typography>
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="investment table">
            <TableHead>
              <TableRow>
                <TableCell>Area</TableCell>
                <TableCell align="right">No Of Students</TableCell>
                <TableCell align="right">Total Revenue</TableCell>
                <TableCell align="right">Franchisee Share</TableCell>
                <TableCell align="right">Expenses</TableCell>
                <TableCell align="right">Profit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investmentDataWritten.map((row) => (
                <TableRow key={row.area}>
                  <TableCell component="th" scope="row">{row.area}</TableCell>
                  <TableCell align="right">{row.students}</TableCell>
                  <TableCell align="right">{row.revenue}</TableCell>
                  <TableCell align="right">{row.share}</TableCell>
                  <TableCell align="right">{row.expenses}</TableCell>
                  <TableCell align="right">{row.profit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default InvestmentWritten;

import { Box, Paper, Typography,Container } from '@mui/material';
import processF from '../../../assets/images/howitworks/processF.jpg'; // replace with your actual image path
import './processFlow.css';

const ProcessFlow = () => {
    return (
      <Box sx={{ bgcolor: '#f9f9f9', p: '30px' }}>
        <Container sx={{ marginTop: 0 }}>
          <Typography variant="h4" gutterBottom>Process</Typography>
          <Paper className="process-container ">
            <img src={processF} alt="About UPL Process" className="process-image" />
          </Paper>
        </Container>
      </Box>
    );
  };
export default ProcessFlow;
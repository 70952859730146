import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Navbar from './components/Navbar/Navbar';
import Benefits from './components/Benefits/Benefits';
import { CssBaseline } from '@mui/material';
import FAQ from './components/faq/FAQ';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import HowItWorks from './components/HowItWorks/HowItWorks';
import AboutUs from './components/AboutUs/aboutus';
import CourseList from './components/Courses/CourseList';
import OfflineCourses from './components/Courses/OfflineCourses';
import ITTrainingCourses from './components/Courses/ITTrainingCourses';
import AdvancedCourses from './components/Courses/AdvancedCourses';
import FormComponent from './components/Connect/Connect';
import CertificateCourses from './components/Courses/CertificateCourses';
import IndustryReadiness from './components/Courses/IndustryReadiness';
import ScrollToTopButton from './components/ScrollTop';

function App() {
  return (
    <Provider store={store}>
      {/* <CssBaseline /> */}
      <Router >
        <Navbar />
        <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/aboutUs" element={<AboutUs />} />
              <Route exact path="/benefits" element={<Benefits />} />
              <Route exact path="/how-it-works" element={<HowItWorks />} />
              <Route exact path="/faqs" element={<FAQ />} />
              <Route path="/courselist"  element={<CourseList/>} />
              <Route path="/offline-courses" element={<OfflineCourses/>} />
              <Route path="/it-training-courses" element={<ITTrainingCourses/>} />
              <Route path="/advanced-courses" element={<AdvancedCourses/>} />
              <Route path="/Connect" element={<FormComponent/>} />
              <Route path="/CertificateCourses" element={<CertificateCourses/>} />
              <Route path="/IndustryReadiness" element={<IndustryReadiness/>} />
            </Routes>
          <ScrollToTopButton />
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;

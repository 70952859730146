import React from 'react';
import { Container, Typography, Grid, Paper, Box,IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import image from '../../assets/images/courses/course1.jpg'; // Import the image
import './CertificateCourses.css';

const CertificateCourses = () => {
    const navigate = useNavigate();
  return (
    <Container sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>Certificate Courses (PG Diploma)</Typography>
          <IconButton onClick={()=>{navigate(-1)}} sx={{ mt: 1 }}>
        <ArrowBackIcon />
      </IconButton>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src={image} alt="Certificate Courses" style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              Overview
            </Typography>
            <Typography variant="body1" paragraph>
              It allows students to build a solid understanding of the basics before delving into more advanced topics.
              The course is divided into three semesters with a focus on foundational concepts in IT during the first
              semester followed by specialized tracks in Artificial Intelligence, Web development, Automation Testing,
              Cybersecurity, and Data science in the second semester.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              Specializations
            </Typography>
            <Typography variant="body1" paragraph>
              <ul>
                <li>Artificial Intelligence</li>
                <li>Automation Testing</li>
                <li>Cyber Security</li>
                <li>Data Science</li>
                <li>Web Programming</li>
              </ul>
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              First Semester: Core Module Fundamentals
            </Typography>
            <Typography variant="body1" paragraph>
              The core modules provide essential knowledge and skills that are applicable across various domains within
              information technology. This course would serve as a solid foundation for students who wish to pursue
              further specialization in areas like web development, mobile development, cybersecurity, data science, or
              others.
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              Second Semester: Specialization
            </Typography>
            <Typography variant="body1" paragraph>
              Specialization modules in an IT program allow students to delve deeper into specific areas of interest or
              career paths with in-depth knowledge and skills in specific areas of IT, allowing them to develop expertise
              and pursue careers in their chosen fields.
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 2, mb: 2  }}>
            <Typography variant="h5" gutterBottom>
              Third Semester: Capstone Project and Live Project
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Capstone Project:</strong> This project allows students to integrate their knowledge and skills
              gained from the specialization track into a comprehensive project. It could be a software application, a
              research project, or a solution to a real-world problem.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Live Project:</strong> Students participate in a Live project with a company or organization
              relevant to their specialization track. This provides practical experience, networking opportunities, and
              a chance to apply classroom learning in a professional setting.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CertificateCourses;

import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import aboutImage from '../../assets/images/aboutus.jpg'; 
import './aboutus.css'; 

const AboutUs = () => {
  return (
    <Box className="about-us">
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="h4" gutterBottom>
              ABOUT US
            </Typography>
            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <Typography variant="body1" paragraph>
              Franchises are a recurring trend in today's market. They are the more secure and successful options for starting one's own business. At UPL, you can purchase tried-and-true business models from us. You can be confident in finding the best franchise opportunities with us in 2024 for you, no matter what your preferences are in terms of sector, location, or investment.
            </Typography>
            </Paper>
            <Typography variant="h5" gutterBottom>
              Unlimited Power Full Learning (UPL)
            </Typography>
            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <Typography variant="body1" paragraph>
              Where our mission is to bridge the gap between students and the dynamic expectations of the IT industry. UPL is more than an organization; it's a visionary initiative aimed at overcoming challenges and nurturing a culture of continuous learning. At UPL, we aspire to create an environment that not only meets the evolving needs of the IT sector but also empowers individuals to thrive in this ever-changing landscape.
            </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Paper className="image-container">
              <img src={aboutImage} alt="About UPL" className="about-image" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
    padding: 20px 0;
    background-color: '#f9f9f9';
  }
  
  .image-container {
    overflow: hidden;
    position: relative;
    padding-top: 75%; /* 4:3 Aspect Ratio */
  }
  
  .about-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AboutUs/aboutus.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB,EAAE,qBAAqB;EACzC;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,0DAA0D;EAC5D","sourcesContent":[".about-us {\n    padding: 20px 0;\n    background-color: '#f9f9f9';\n  }\n  \n  .image-container {\n    overflow: hidden;\n    position: relative;\n    padding-top: 75%; /* 4:3 Aspect Ratio */\n  }\n  \n  .about-image {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
